import Layout from '../../layout/Layout';
import './KontaktPage.css';

function KontaktPage () {

    const leftKontaktPageContent = (
         <h1 className='titles-page'>KONTAKT</h1> )

    const rightKontaktPageContent = (
            <>
                <div className='email-container'>
                <h2 className='titles'>E-mail</h2>
                <p>info@strawberrybeat.de</p>
                </div>

                <div>
                <h2 className='titles'>Fon</h2>
                <p>030/91903833 (Peter Campignier)</p>
                <p>0162/4466114 (Jürgen Grohs)</p>
                </div>
            </>
            )

    return (

        <Layout left={leftKontaktPageContent} right={rightKontaktPageContent}>

            
        </Layout>
    )
}

export default KontaktPage