import Layout from "../../layout/Layout";
import "./HomePage.css";

function HomePage() {
  const leftHomePageContent = <h1 className="titles-page">STRAWBERRY BEAT</h1>;

  const rightHomePageContent = (
    <p className="home-text">
      Beatlescovers auf höchstem Niveau
      <br />
      <br />
      Die Beatlescoverband Strawberrybeat aus Berlin bietet ein umfangreiches
      Repertoire aus dem Schaffen der wohl berühmtesten Band der Welt.
      <br />
      <br />
      Mit authentischem Sound und viel Leidenschaft spielen „Strawberrybeat“
      sowohl bekannte als auch unbekanntere Songs der legendären Liverpooler und
      als Besonderheit sogar Songs, die die Beatles selbst nie live auf einer
      Bühne präsentiert haben.
      <br />
      <br />
      Ein Highlight für jede Party, denn die Musik der Beatles ist zeitlos, und
      auch wenn Sie kein erklärter Fan sind, werden Sie die meisten Lieder
      mitsingen können.
      <br />
      <br />
      Mitsingen, träumen, in Erinnerungen schwelgen, ausgelassen tanzen, alles
      ist möglich.
      <br />
      <br />
      Verschiedene Programme aus unterschiedlichen Epochen der Beatles,
      präsentiert von professionellen Musikern, garantieren einen
      abwechslungsreichen Abend mit packender Livemusik.
      <br />
      <br />
      Eine Liveband für große und kleine Bühnen bei Partys, Events, Galas,
      Firmenfesten und anderen Veranstaltungen.
      <br />
      <br />
      All you need is Strawberrybeat
      <br />
      <br />
    </p>
  );

  return (
    <Layout left={leftHomePageContent} right={rightHomePageContent}></Layout>
  );
}

export default HomePage;
