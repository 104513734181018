import HeaderLayout from "./headerLayout/HeaderLayout.jsx"
import './Layout.css';
import NavigationLayout from "./navigationLayout/NavigationLayout.jsx"



function Layout (props) {

    return (

    <div className="layout-container">
    <HeaderLayout></HeaderLayout>
    <NavigationLayout></NavigationLayout>
    <main >
    <div className="main-container-left">{props.left}</div>
    <div className="main-container-right">{props.right}</div>
    </main>
    </div>
    )
}

export default Layout