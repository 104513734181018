import Layout from "../../layout/Layout";
import "./ImpressumPage.css";

function ImpressumPage() {
  const leftImpressumPageContent = <h1 className="titles-page">IMPRESSUM</h1>;

  const rightImpressumPageContent = (
    <p className="impressum-text">Peter Campignier <br /> Pankstraße 56 <br /> 13357 Berlin <br /> Tel.: 030 – 91903833</p>
  );

  return (
    <Layout
      left={leftImpressumPageContent}
      right={rightImpressumPageContent}
    ></Layout>
  );
}

export default ImpressumPage;
