import './HeaderLayout.css';
import StrawberryPic from '../../../img/strbe.jpeg'
import { Link } from 'react-router-dom';

function HeaderLayout() {
  return (
    <header>
      <div  className='img-container'>
      <Link to='/home'><img 
        className="" 
        src={StrawberryPic} 
        width="110px" 
        height="80px"
        alt="logo-pic" 
      /></Link>
      </div>
      <div>
        <h1>STRAWBERRY BEAT</h1>
        <h2>Die Beatles Cover-Band</h2>
      </div>
    </header>
  );
}

export default HeaderLayout;

