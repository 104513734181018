import "./BandPage.css";
import Layout from "../../layout/Layout.jsx";
import PersonalImage1 from "../../../img/jurgenGrohs.jpg";
import PersonalImage2 from "../../../img/martinSchmidt.jpg";
import PersonalImage3 from "../../../img/peterCampignier.jpg";
import PersonalImage4 from "../../../img/thomasKoch.jpg";
import PersonalImage5 from "../../../img/dimoWendt.jpg";

function BandPage() {
  const leftBandPageContent = <h1 className="titles-page">BAND</h1>;

  const rightBandPageContent = (
    <>
      <div className="">
        <div>
          <img src={PersonalImage1} alt="jürgenGrohs" />
        </div>
        <h2 className="member-names-h2">Jürgen Grohs</h2>
      </div>

      <div>
        <div>
          <img src={PersonalImage2} alt="" />
        </div>
        <h2 className="member-names-h2">Martin Schmidt</h2>
      </div>
      <div>
        <div>
          <img src={PersonalImage3} alt="" />
        </div>
        <h2 className="member-names-h2">Peter Campignier</h2>
      </div>
      <div>
        <div>
          <img src={PersonalImage4} alt="" />
        </div>
        <h2 className="member-names-h2">Thomas Koch</h2>
      </div>
      <div>
        <div>
          <img src={PersonalImage5} alt="" />
        </div>
        <h2 className="member-names-h2">Dimo Wendt</h2>
      </div>

      <h3 className="Photographer-name">Photos: Dirk Loombeek, Peter Altmann</h3>
    </>
  );
  return (
    <Layout left={leftBandPageContent} right={rightBandPageContent}></Layout>
  );
}

export default BandPage;
