import { useState } from "react";
import "./NavigationLayout.css";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function NavigationLayout() {
  const [menuIsopen, setMenuIsopen] = useState(false);
  const smallDevice = useMediaQuery({ query: "(max-width: 800px)" });

  const clickToOpenMenu = () => {
    setMenuIsopen(!menuIsopen);
  };

  return (
    <div className="container-navbar">
      {/* en la primera  (style={{ display: smallDevice ? "block" : "..... solo cambia el estilo atravez de css y renderiza todos los elenentos mientas que en la segunda solo si la condicion se cumple renderiza los elemento, en la primera siempre los elementos pasan al dom y en la segunda no*/}
      <div
        className="icon-container"
        style={{ display: smallDevice ? "block" : "none" }}
      >
        <button className="burger-button" onClick={clickToOpenMenu}>
          {!menuIsopen && (
            <svg
              className="menu-icon-svg"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v15z" />
            </svg>
          )}
          {menuIsopen && (
            <svg
              className="menu-icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
         
        </button>
        <span className="menu-text">Menü</span>
        
      </div>

      {menuIsopen && smallDevice ? (
        <ul className="navigation-container-ul">
          <li className="li-items">
            <NavLink to="/band">Band</NavLink>
          </li>

          <li className="li-items">
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
          <li className="li-items">
            <NavLink to="/impressum">Impressum</NavLink>
          </li>
        </ul>
      ) : null}

      {!smallDevice ? (
        <ul className="navigation-container-ul">
          <li className="li-items">
            <NavLink to="/band">Band</NavLink>
          </li>

          <li className="li-items">
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
          <li className="li-items">
            <NavLink to="/impressum">Impressum</NavLink>
          </li>
        </ul>
      ) : null}
    </div>
  );
}

export default NavigationLayout;
